import { Roboto_Condensed } from 'next/font/google';
import {
  FaWindowClose,
  FaShieldVirus,
  FaHeadSideMask,
  FaPumpMedical,
} from 'react-icons/fa';
import RequestOrderInput from '../components/RequestOrderInput';
import { hasClass } from '../helpers';
import { Flex, Box, Text, useMediaQuery } from '@chakra-ui/react';
import Image from 'next/image';
import Layout from '../components/Layout';
import Carousel from '../components/Carousel';
import Seo from '../components/Seo';
import DownloadApp from '../components/DownloadApp';
import { useRouter } from 'next/router';

const SAFETY = [
  {
    icon: <FaHeadSideMask size={'30px'} color="#11a00d" />,
    title: 'Face Coverings',
    description: `We provide complementary face masks if you require one.
    Rest-assure, our Chauffeurs will be wearing their masks if you
    require so!`,
  },
  {
    icon: <FaPumpMedical size={'30px'} color="#11a00d" />,
    title: 'Cleaning & Sanitisation',
    description: `So you can have the best experience, at Vert the cars must be
    kept in pristine conditions. Our Chauffeurs are always paying
    attention for the cars to be cleaned and sanitised after each
    ride!`,
  },
  {
    icon: <FaShieldVirus size={'30px'} color="#11a00d" />,
    title: 'Chauffeur safety training',
    description: `All our Chauffeurs are attending and successfully completing one
    of the most comprehensive training programme in the industry
    before they start driving with us. With Vert, Safety comes as a
    Standard!`,
  },
];

// Subsets are really important.
const roboto = Roboto_Condensed({
  weight: '400', // if single weight, otherwise you use array like [400, 500, 700],
  style: 'normal', // if single style, otherwise you use array like ['normal', 'italic']
  subsets: ['latin'],
});

const Home = ({
  baseUrl,
  basicPwdOne,
  basicPwdTwo,
  basicUser,
  googleApiKey,
  jwtToken,
  global,
}) => {
  const router = useRouter();

  const onClickCloseVideo = () => {
    const videoSrc = document.querySelector('#player-1');
    const videoPopup = document.querySelector('.video-popup');

    const isOpen = hasClass(videoPopup, 'open-video');
    if (isOpen) {
      videoPopup.classList.remove('open-video');
      videoSrc.setAttribute('src', '');
    }
  };
  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)');
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');
  const [isLargerThan728] = useMediaQuery('(min-width: 728px)');

  let location = 'London and Essex';

  const string = router.query.location;

  if (typeof string === 'string') {
    // Check if string is a valid string
    location = string.replace(/([A-Z])/g, ' $1');
  }

  // const globalSeo = global.find(
  //   (item: any) => item?.attributes?.page === 'global'
  // );

  let seo: {
    metaTitle: string;
    metaDescription?: string;
  } = {
    metaTitle: `${location} All-Electric Chauffeur Service`,
  };

  if (location !== 'London and Essex') {
    seo.metaDescription = `${location} chauffeur service discover our exceptional. Whether you need executive travel, airport transfers, or personalized city tours, our${location} services offer the epitome of luxury and convenience. Explore London in style with our all-electric chauffeur rides.`;
  }

  return (
    <Layout withAppNavBar={false} willAnimate={true}>
      <Seo seo={seo} />
      <Flex
        p={isLargerThan728 ? 20 : 10}
        backgroundColor={'black'}
        justify={'center'}
      >
        <Flex
          py={'70px'}
          direction="row"
          justifyContent="center"
          alignItems={'center'}
          maxW={'1580px'}
        >
          <Box py={'60px'} w={isLargerThan1024 ? '50%' : '100%'}>
            <Text
              className={roboto.className}
              color={'white'}
              fontWeight={'bold'}
              fontSize={'5xl'}
              lineHeight={!isLargerThan728 && '55px'}
            >
              Get a ride in minutes!
            </Text>
            <Box py={3}>
              <Text color={'white'} fontSize={'large'} mb={0}>
                Experience the future of luxury travel with our
                chauffeur-driven, all-electric service.
              </Text>
              <Text color={'white'} fontSize={'large'}>
                Your next executive ride is just a tap away.
              </Text>
            </Box>
            <RequestOrderInput
              jwtToken={jwtToken}
              googleApiKey={googleApiKey}
              width={isLargerThan728 ? 500 : 300}
            />
          </Box>
          {isLargerThan1024 && (
            <Box py={'60px'}>
              <Box className="home-img">
                <Box position={'absolute'} ml={'130px'} zIndex={1}>
                  <Image
                    className="phone-hero"
                    src="/images/firstscreen.png"
                    alt="vert app"
                    width={240}
                    height={420}
                  />
                </Box>
                <Box position={'absolute'} borderWidth={0}>
                  <Image
                    src="/images/background.png"
                    alt="background"
                    width={800}
                    height={700}
                  />
                </Box>
                <Box
                  className="circle"
                  borderWidth={1}
                  borderColor={'rgba(255, 255, 255, 0.2)'}
                  rounded={'50%'}
                  w={430}
                  h={430}
                  ml={9}
                  padding={1}
                  zIndex={9}
                  position={'absolute'}
                >
                  <Image
                    src="/images/car.svg"
                    alt="car"
                    width={110}
                    height={120}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Flex>
      </Flex>

      <Flex
        my={20}
        direction="column"
        justifyContent="center"
        alignItems={'center'}
        px={isLargerThan1024 ? '200px' : 10}
      >
        <Box w={'100%'} className="features">
          <Text
            fontSize={isLargerThan728 ? '5xl' : '4xl'}
            className={roboto.className}
            fontWeight={'semibold'}
            lineHeight={isLargerThan728 ? '50px' : '35px'}
          >
            Safety Training
          </Text>
          <Carousel paginationAndArrows={false} itemsToShowInitial={4}>
            {SAFETY.map((item, index) => (
              <Flex
                direction={'column'}
                mx={5}
                my={10}
                p={10}
                shadow={'lg'}
                key={index}
                h={isLargerThan728 ? '400px' : '450px'}
                alignItems={'center'}
                rounded={'md'}
                borderWidth={0.5}
              >
                <Flex
                  alignItems={'center'}
                  justify={'center'}
                  w={200}
                  height={100}
                >
                  {item.icon}
                </Flex>
                <Box mt={5} textAlign={'center'}>
                  <Text className={roboto.className} fontSize={'2xl'}>
                    {item.title}
                  </Text>
                  <Text fontSize={'sm'}>{item.description}</Text>
                </Box>
              </Flex>
            ))}
          </Carousel>
        </Box>
      </Flex>

      <Flex
        direction={isLargerThan1280 ? 'row' : 'column'}
        px={isLargerThan1024 ? '200px' : 10}
        justify={'space-between'}
        mb={20}
      >
        <Box maxW={'600px'}>
          <Image
            src="/images/man-and-phone.png"
            alt="vector"
            width={300}
            height={420}
          />
        </Box>
        <Box maxW={'600px'} mt={isLargerThan1280 ? 0 : 10}>
          <Text m={0} fontSize={'xl'} fontWeight={'medium'} color={'gray.600'}>
            Services
          </Text>
          <Text
            fontSize={isLargerThan728 ? '5xl' : '4xl'}
            className={roboto.className}
            fontWeight={'semibold'}
            lineHeight={isLargerThan728 ? '50px' : '35px'}
          >
            Executive Chauffeured Tesla Service
          </Text>
          <Text>
            Highly skilled, hand-picked fully trained Chauffeurs are always
            ready to provide a professional service for any journey: airport
            transfers, business trips, corporate events, roadshows, weddings,
            shopping trips and many more.
          </Text>
        </Box>
      </Flex>

      <Flex
        direction={isLargerThan1280 ? 'row' : 'column'}
        px={isLargerThan1024 ? '200px' : 10}
        justify={'space-between'}
        mb={20}
      >
        <Box maxW={'600px'} mt={isLargerThan1280 ? 0 : 10}>
          <Text m={0} fontSize={'xl'} fontWeight={'medium'} color={'gray.600'}>
            Payments
          </Text>
          <Text
            fontSize={isLargerThan728 ? '5xl' : '4xl'}
            className={roboto.className}
            fontWeight={'semibold'}
            lineHeight={isLargerThan728 ? '50px' : '35px'}
          >
            Multiple Payment Methods
          </Text>
          <Text>
            Your money, your choice - Personal, Family or Business trips can be
            paid by card or eye-credits. You can purchase, share or redistribute
            your eye-credits.
          </Text>
        </Box>
        <Box maxW={'600px'}>
          <Image
            src="/images/payments.png"
            alt="payments"
            width={300}
            height={350}
          />
        </Box>
      </Flex>

      <Flex
        direction={isLargerThan1280 ? 'row' : 'column'}
        px={isLargerThan1024 ? '200px' : 10}
        justify={'space-between'}
        mb={20}
      >
        <Box maxW={'600px'} mt={isLargerThan1280 ? 0 : 10}>
          <Text m={0} fontSize={'xl'} fontWeight={'medium'} color={'gray.600'}>
            Promotions
          </Text>
          <Text
            fontSize={isLargerThan728 ? '5xl' : '4xl'}
            className={roboto.className}
            fontWeight={'semibold'}
            lineHeight={isLargerThan728 ? '50px' : '35px'}
          >
            Coupons & referral programs
          </Text>
          <Text>
            We like to reward you at every stage! As a loyal customer you will
            benefit from discounts on rides, personalized offers from
            prestigious brands and many other surprises...
          </Text>
        </Box>
        <Box maxW={'600px'}>
          <Image
            src="/images/engagement.png"
            alt="engagement"
            width={500}
            height={400}
          />
        </Box>
      </Flex>

      <Flex
        direction={isLargerThan1280 ? 'row' : 'column'}
        px={isLargerThan1024 ? '200px' : 10}
        justify={'space-between'}
        mb={20}
      >
        <Box maxW={'600px'}>
          <Image src="/images/sos.png" alt="sos" width={300} height={340} />
          <Box className="circle-ripple"></Box>
        </Box>
        <Box maxW={'600px'} mt={isLargerThan1280 ? 0 : 10}>
          <Text m={0} fontSize={'xl'} fontWeight={'medium'} color={'gray.600'}>
            Safety
          </Text>
          <Text
            fontSize={isLargerThan728 ? '5xl' : '4xl'}
            className={roboto.className}
            fontWeight={'semibold'}
            lineHeight={isLargerThan728 ? '50px' : '35px'}
          >
            Panic Button, Safety Rating, emergency training
          </Text>
          <Text>
            Safety as a Standard - Panic Button directly integrated with the
            emergency services, Safety rating and review process to constantly
            improve our services. All our Chauffeurs are Safety as a Standard
            affiliate members as an entry level.
          </Text>
        </Box>
      </Flex>

      <Flex
        direction={isLargerThan1280 ? 'row' : 'column'}
        px={isLargerThan1024 ? '200px' : 10}
        justify={'space-between'}
        mb={20}
      >
        <Box maxW={'600px'}>
          <Image
            src="/images/locations.png"
            alt="profiles"
            width={500}
            height={490}
          />
        </Box>
        <Box maxW={'600px'} mt={isLargerThan1280 ? 0 : 10}>
          <Text m={0} fontSize={'xl'} fontWeight={'medium'} color={'gray.600'}>
            Profiles
          </Text>
          <Text
            fontSize={isLargerThan728 ? '5xl' : '4xl'}
            className={roboto.className}
            fontWeight={'semibold'}
            lineHeight={isLargerThan728 ? '50px' : '35px'}
          >
            Personal, Family or Business
          </Text>
          <Text>
            One of the most adaptable and versatile travelling module on the
            market. Personal, Family or Business, you have multiple choices on
            how to set up your profiles. Invite, manage or allocate travel
            credit, create departments or simply send a gift. These are only few
            examples on how to manage your daily commute as per your needs.
          </Text>
        </Box>
      </Flex>

      <Flex
        direction={isLargerThan1280 ? 'row' : 'column'}
        px={isLargerThan1024 ? '200px' : 10}
        justify={'space-between'}
        mb={20}
      >
        <Box maxW={'600px'}>
          <Image
            src="/images/planning.png"
            alt="planning"
            width={300}
            height={360}
          />
        </Box>
        <Box maxW={'600px'} mt={isLargerThan1280 ? 0 : 10}>
          <Text m={0} fontSize={'xl'} fontWeight={'medium'} color={'gray.600'}>
            Planner assistant
          </Text>
          <Text
            fontSize={isLargerThan728 ? '5xl' : '4xl'}
            className={roboto.className}
            fontWeight={'semibold'}
            lineHeight={isLargerThan728 ? '50px' : '35px'}
          >
            Schedule your rides
          </Text>
          <Text>
            Manage your rides as per your needs. Choose your dates and times,
            service and other options available in the App. Plan ahead and stay
            worry free!
          </Text>
        </Box>
      </Flex>

      <Flex
        direction={isLargerThan1280 ? 'row' : 'column'}
        px={isLargerThan1024 ? '200px' : 10}
        justify={'space-between'}
        mb={20}
      >
        <Box maxW={'600px'} mt={isLargerThan1280 ? 0 : 10}>
          <Text m={0} fontSize={'xl'} fontWeight={'medium'} color={'gray.600'}>
            Options
          </Text>
          <Text
            fontSize={isLargerThan728 ? '5xl' : '4xl'}
            className={roboto.className}
            fontWeight={'semibold'}
            lineHeight={isLargerThan728 ? '50px' : '35px'}
          >
            Customise your rides
          </Text>
          <Text>
            We always strive to excel. Personalise your journey to your needs:
            temperature, radio station, music, silence. You are the master of
            your choice.
          </Text>
        </Box>
        <Box maxW={'600px'}>
          <Image
            src="/images/options.png"
            alt="extras"
            width={300}
            height={360}
          />
        </Box>
      </Flex>

      <Flex
        direction={isLargerThan1280 ? 'row' : 'column'}
        px={isLargerThan1024 ? '200px' : 10}
        justify={'space-between'}
        mb={20}
      >
        <Box maxW={'600px'}>
          <Image
            src="/images/notifications.png"
            alt="notifications"
            width={370}
            height={300}
          />
        </Box>
        <Box maxW={'600px'} mt={isLargerThan1280 ? 0 : 10}>
          <Text m={0} fontSize={'xl'} fontWeight={'medium'} color={'gray.600'}>
            Your Order A to Z
          </Text>
          <Text
            fontSize={isLargerThan728 ? '5xl' : '4xl'}
            className={roboto.className}
            fontWeight={'semibold'}
            lineHeight={isLargerThan728 ? '50px' : '35px'}
          >
            Confirmed-On the Way-Arrived-Share-Finished
          </Text>
          <Text>
            We will always update you at every stage of your trip so no need to
            worry we will notify you every time the order status has changed.
            Safe rides!
          </Text>
        </Box>
      </Flex>

      <Flex
        direction={isLargerThan1280 ? 'row' : 'column'}
        px={isLargerThan1024 ? '200px' : 10}
        justify={'space-between'}
        mb={20}
      >
        <Box maxW={'600px'} mt={isLargerThan1280 ? 0 : 10}>
          <Text m={0} fontSize={'xl'} fontWeight={'medium'} color={'gray.600'}>
            Communication
          </Text>
          <Text
            fontSize={isLargerThan728 ? '5xl' : '4xl'}
            className={roboto.className}
            fontWeight={'semibold'}
            lineHeight={isLargerThan728 ? '50px' : '35px'}
          >
            Stay in touch
          </Text>
          <Text>
            Always stay connected! Additional communication or instruction, last
            minute information or preference, we want to ensure that you will
            always stay in touch with your Chauffeur.
          </Text>
        </Box>
        <Box maxW={'600px'}>
          <Image
            src="/images/get_in_touch.png"
            alt="calls"
            width={300}
            height={360}
          />
        </Box>
      </Flex>

      <Flex
        direction={isLargerThan1280 ? 'row' : 'column'}
        px={isLargerThan1024 ? '200px' : 10}
        justify={'space-between'}
        mb={20}
      >
        <Box maxW={'600px'}>
          <Image
            src="/images/issues.png"
            alt="issues"
            width={300}
            height={300}
          />
        </Box>
        <Box maxW={'600px'} mt={isLargerThan1280 ? 0 : 10}>
          <Text m={0} fontSize={'xl'} fontWeight={'medium'} color={'gray.600'}>
            Trip Issues
          </Text>
          <Text
            fontSize={isLargerThan728 ? '5xl' : '4xl'}
            className={roboto.className}
            fontWeight={'semibold'}
            lineHeight={isLargerThan728 ? '50px' : '35px'}
          >
            Lost property-incidents-fares
          </Text>
          <Text>
            We are ALWAYS here to help! We are all humans so at times we might
            experience some issues. We have implemented tools so we can try and
            resolve any issue ASAP and as best as we can. If you have lost or
            forgot something, experienced an incident or there is an issue with
            the fare, get in touch! Use &apos;Trip Issue&apos; in the App and
            describe what issue you have experienced. You will always be updated
            with the resolution.
          </Text>
        </Box>
      </Flex>

      <Flex
        direction={isLargerThan1280 ? 'row' : 'column'}
        px={isLargerThan1024 ? '200px' : 10}
        justify={'space-between'}
        mb={20}
      >
        <Box mt={isLargerThan1280 ? 0 : 10}>
          <Text m={0} fontSize={'xl'} fontWeight={'medium'} color={'gray.600'}>
            Vert and you
          </Text>
          <Text
            fontSize={isLargerThan728 ? '5xl' : '4xl'}
            className={roboto.className}
            fontWeight={'semibold'}
            lineHeight={isLargerThan728 ? '50px' : '35px'}
          >
            Let&apos;s help our wonderful planet
          </Text>
          <Text mt={10}>
            Starting the journey to become a carbon-neutral business does not
            only foster positive feelings from customers, employees also feel
            proud for playing an active role in creating a safer and greener
            environment.
            <br />
            <br />
            As more companies are going green, the idea of going carbon neutral
            becomes much more tantalizing and it is now becoming a desired
            status to be an environmentally friendly business.
            <br />
            <br />
            We are very proud to offer you the utimate green Chauffeur service
            while helping reducing carbon emission.
            <br />
            <br />
            Every Vert mile is a breath of fresh air!!!
          </Text>
        </Box>
      </Flex>

      <Box className="video-popup">
        <Box className="video-popup-inner">
          <Box
            onClick={() => onClickCloseVideo()}
            position={'absolute'}
            cursor={'pointer'}
            zIndex={9}
          >
            <FaWindowClose size={35} color="white" />
          </Box>
          <Box h={'20px'}></Box>
          <Box className="iframe-box" mt={30}>
            <iframe
              id="player-1"
              src=""
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Box>
        </Box>
      </Box>
      <DownloadApp type="client" />
    </Layout>
  );
};

export async function getServerSideProps() {
  return {
    props: {
      baseUrl: process.env.NEXT_APP_BASE_URL,
      basicUser: '$2b$10$' + process.env.NEXT_VERT_BASIC_USER,
      basicPwdOne: '$2b$10$' + process.env.NEXT_VERT_BASIC_PWD_PART_ONE,
      basicPwdTwo: process.env.NEXT_VERT_BASIC_PWD_PART_TWO,
      googleApiKey: process.env.NEXT_GOOGLE_API_KEY,
      jwtToken: process.env.JWT_SECRET,
    },
  };
}

export default Home;
