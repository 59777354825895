/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FaPlay } from 'react-icons/fa';
import { useRouter } from 'next/router';
import useAddressPredictions from '../../hooks/useAddressPredictions';
import { hasClass } from '../../helpers';
import SearchResults from '../SearchAddressResults';
import { Flex, Box, Button, Icon, Text, Input } from '@chakra-ui/react';
import Image from 'next/image';
import { MdInfo } from 'react-icons/md';
import Link from 'next/link';
import fetchJson from '../../lib/fetchApi';
import { GET_PRICE } from '../../apollo/queries/GetPrice';
import { useApolloClient } from '@apollo/client';
import { GET_CAR_SERVICES } from '../../apollo/queries/GetCarServices';
import { convertString } from '../ServiceGroups';

const RequestOrderInput = ({ jwtToken, googleApiKey, width }) => {
  const [pickUp, setPickUp] = useState('');
  const [dropOff, setDropOff] = useState('');
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [selectedPickUp, setSelectedPickUp] = useState(false);
  const [selectedDropOff, setSelectedDropOff] = useState(false);
  const [showSearchResultsPickUp, setShowSearchResultsPickUp] = useState(false);
  const [showSearchResultsDropOff, setShowSearchResultsDropOff] =
    useState(false);
  const [validPickUp, setValidPickUp] = useState(true);
  const [validDropOff, setValidDropOff] = useState(true);
  const [searchResultsPickUp, setSearchResultsPickUp] = useState([]);
  const [searchResultsDropOff, setSearchResultsDropOff] = useState([]);
  const [servicesRates, setServiceRates] = useState([]);

  const navigate = useRouter();
  const apolloClient = useApolloClient();

  // Get PickUp Predictions
  const predictionsPickUp = useAddressPredictions(googleApiKey, pickUp);
  // Get DropOff Predictions
  const predictionsDropOff = useAddressPredictions(googleApiKey, dropOff);

  useEffect(() => {
    setSearchResultsPickUp(predictionsPickUp);
    setSearchResultsDropOff(predictionsDropOff);
  }, [predictionsPickUp, predictionsDropOff]);

  useEffect(() => {
    if (selectedPickUp && selectedDropOff) {
      getCarTypes();
    }
  }, [selectedPickUp, selectedDropOff]);

  // Handle address input content
  const handleChangePickUp = (address) => {
    setPickUp(address);
    setSelectedPickUp(false);
    setValidPickUp(true);
    address.length > 2
      ? setShowSearchResultsPickUp(true)
      : setShowSearchResultsPickUp(false);
  };

  // Handle address input content
  const handleChangeDropOff = (address) => {
    setDropOff(address);
    setSelectedDropOff(false);
    setValidDropOff(true);
    address.length > 2
      ? setShowSearchResultsDropOff(true)
      : setShowSearchResultsDropOff(false);
  };

  // Handle Place selection then set drop off location
  const handlePlaceSelect = (location, isPickUp) => {
    const geocoder = new google.maps.Geocoder();
    if (isPickUp) {
      geocoder.geocode(
        {
          placeId: location.place_id,
        },
        (responses, status) => {
          if (status === 'OK') {
            setPickUp(location.description);
            setOrigin({
              latitude: responses[0].geometry.location.lat(),
              longitude: responses[0].geometry.location.lng(),
              formatted_address: location.description,
            });
            setSelectedPickUp(true);
          }
        }
      );
    }
    if (!isPickUp) {
      geocoder.geocode(
        {
          placeId: location.place_id,
        },
        (responses, status) => {
          if (status === 'OK') {
            setDropOff(location.description);
            setDestination({
              latitude: responses[0].geometry.location.lat(),
              longitude: responses[0].geometry.location.lng(),
              formatted_address: location.description,
            });
            setSelectedDropOff(true);
          }
        }
      );
    }
  };

  const getCarTypes = async () => {
    let { data, error } = await apolloClient.query({
      query: GET_CAR_SERVICES,
      context: {
        clientName: 'vert',
      },
      variables: {
        fleetName: 'vert',
      },
    });

    if (!error) {
      getPrices(data.getCarServices.map((service) => service.type));
    }
  };

  const getPrices = async (carServices) => {
    const { data, error } = await apolloClient.query({
      query: GET_PRICE,
      context: {
        clientName: 'vert',
      },
      variables: {
        origin: {
          formatted_address: origin.formatted_address,
          latitude: origin.latitude,
          longitude: origin.longitude,
        },
        destination: {
          formatted_address: destination.formatted_address,
          latitude: destination.latitude,
          longitude: destination.longitude,
        },
        via: [],
        serviceTypes: carServices,
        companyId: '3',
        isHourly: false,
      },
    });

    if (!error) {
      setServiceRates(data.getPrice);
    }
  };

  const onScheduleNow = async () => {
    const { error, data }: { error: boolean; data: any } = await fetchJson(
      `/api/jwt`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            origin: origin,
            destination: destination,
          },
        }),
      }
    );
    if (!error && jwtToken) {
      navigate.push(`/app/order?t=${data}`);
    } else {
      navigate.push('/app/auth/login');
    }
  };

  const onClickPlay = () => {
    const videoSrc = document.querySelector('#player-1');
    const videoPopup = document.querySelector('.video-popup');

    const isOpen = hasClass(videoPopup, 'open-video');
    if (isOpen) {
      videoPopup.classList.remove('open-video');
      videoSrc.setAttribute('src', '');
    } else {
      videoPopup.classList.add('open-video');
      if (videoSrc.getAttribute('src') === '') {
        videoSrc.setAttribute(
          'src',
          'https://www.youtube.com/embed/MbPP1QRx2lQ?controls=0&autoplay=1&modestbranding=1'
        );
      }
    }
  };

  return (
    <Box className="order-request">
      <Box className="order-request-form">
        <Flex direction={'column'}>
          <Box>
            <Input
              id="autocomplete-picup"
              type="text"
              placeholder="Enter pick-up location"
              backgroundColor={'white'}
              py={6}
              autoComplete="off"
              value={pickUp}
              style={{ borderColor: validPickUp ? 'transparent' : 'red' }}
              onChange={(e) => handleChangePickUp(e.target.value)}
              mb={2}
              maxW={'500px'}
              _focus={{
                borderColor: 'brand.900',
              }}
              focusBorderColor="transparent"
            />
            {!selectedPickUp &&
              showSearchResultsPickUp &&
              searchResultsPickUp &&
              searchResultsPickUp.length > 0 && (
                <SearchResults
                  width={width}
                  mt={0}
                  data={searchResultsPickUp}
                  onChange={(res) => handlePlaceSelect(res, true)}
                />
              )}
          </Box>
          <Box>
            <Input
              id="autocomplete-dropoff"
              autoComplete="off"
              type="text"
              placeholder="Enter destination"
              backgroundColor={'white'}
              py={6}
              mb={2}
              value={dropOff}
              style={{ borderColor: validDropOff ? 'transparent' : 'red' }}
              onChange={(e) => handleChangeDropOff(e.target.value)}
              maxW={'500px'}
              _focus={{
                borderColor: 'brand.900',
              }}
              focusBorderColor="transparent"
            />
            {!selectedDropOff &&
              showSearchResultsDropOff &&
              searchResultsDropOff &&
              searchResultsDropOff.length > 0 && (
                <SearchResults
                  width={width}
                  mt={0}
                  data={searchResultsDropOff}
                  onChange={(res) => handlePlaceSelect(res, false)}
                />
              )}
          </Box>
        </Flex>
        {servicesRates.length > 0 && (
          <>
            <Box mt={5} display={'flex'}>
              <Text fontWeight={'medium'} color="white">
                Current Estimated Price
              </Text>
              <Link href="/services">
                <Icon
                  ml={5}
                  color={'#fff'}
                  alignSelf={'center'}
                  as={MdInfo}
                  w={5}
                  h={5}
                  cursor={'pointer'}
                />
              </Link>
            </Box>

            <Box display="flex" mt={5}>
              {servicesRates.map((_item, index) => {
                return (
                  <Box
                    key={index}
                    width="100px"
                    height="120px"
                    backgroundColor="white"
                    marginRight="1rem"
                    borderRadius="md"
                    overflow="hidden"
                    alignItems={'center'}
                    display={'flex'}
                    flexDirection={'column'}
                    p={2}
                  >
                    <Image
                      src={`/images/${_item.service_type}.png`}
                      width={45}
                      height={45}
                      objectFit="contain"
                      alt="service"
                    />
                    <Text
                      fontSize={'12px'}
                      fontWeight={'medium'}
                      textAlign={'center'}
                      color={'black'}
                    >
                      {_item.service_type === 'MPV'
                        ? 'MPV'
                        : convertString(_item.service_type)}
                    </Text>
                    <Text
                      fontSize={'12px'}
                      fontWeight={'medium'}
                      textAlign={'center'}
                      color={'black'}
                    >
                      £{(_item.price / 100).toFixed(2)}
                    </Text>
                  </Box>
                );
              })}
            </Box>
          </>
        )}
        <Box>
          <Flex py={10}>
            <Button
              name="schedule"
              onClick={() => onScheduleNow()}
              type="button"
              backgroundColor={'brand.900'}
              shadow={'md'}
              color={'white'}
              _hover={{
                opacity: 0.7,
              }}
              fontWeight={'medium'}
            >
              Schedule Now
            </Button>
            <Button
              name="play"
              onClick={() => onClickPlay()}
              type="button"
              backgroundColor={'brand.900'}
              shadow={'md'}
              color={'white'}
              _hover={{
                opacity: 0.7,
              }}
              fontWeight={'medium'}
              mx={5}
            >
              <FaPlay size={12} />
            </Button>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default RequestOrderInput;
